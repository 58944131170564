import React from 'react';
import PrivacyTipIcon from '@mui/icons-material/PrivacyTip';
import SyncLockIcon from '@mui/icons-material/SyncLock';
import GppGoodIcon from '@mui/icons-material/GppGood';
import Tooltip from '@mui/material/Tooltip';
import Box from '@mui/material/Box';
import { HpAuthentication } from '../../../../Models';

type HpAuthStateIconProps = {
    hpAuth: HpAuthentication | undefined;
};
export function HpAuthStateIcon({ hpAuth }: HpAuthStateIconProps) {
    let tooltipTitle: string = '';
    let icon: React.JSX.Element = <PrivacyTipIcon />;
    let bgColor: string = 'warning.main';
    let blinkStyle: string = '';

    if (hpAuth?.state === 'none' || !hpAuth) {
        tooltipTitle = 'Este pedido requiere token';
        icon = <PrivacyTipIcon />;
        bgColor = 'warning.main';
        blinkStyle = '';
    }
    if (hpAuth?.state === 'requested' || hpAuth?.state === 'error') {
        tooltipTitle = 'Esperando token...';
        icon = <SyncLockIcon />;
        bgColor = 'warning.main';
        blinkStyle = 'blink-slow';
    }
    if (hpAuth?.state === 'sent') {
        tooltipTitle = 'Token recibido';
        icon = <GppGoodIcon />;
        bgColor = 'success.main';
        blinkStyle = 'blink-fast';
    }

    return (
        <Tooltip title={tooltipTitle}>
            <Box
                className={blinkStyle}
                bgcolor={bgColor}
                sx={{
                    color: 'white',
                    borderRadius: 2,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    padding: '0.2rem',
                }}>
                {icon}
            </Box>
        </Tooltip>
    );
}
